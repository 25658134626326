exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-app-js": () => import("./../../../src/pages/download-app.js" /* webpackChunkName: "component---src-pages-download-app-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integracije-js": () => import("./../../../src/pages/integracije.js" /* webpackChunkName: "component---src-pages-integracije-js" */),
  "component---src-pages-keks-pay-help-me-js": () => import("./../../../src/pages/keks-pay/help-me.js" /* webpackChunkName: "component---src-pages-keks-pay-help-me-js" */),
  "component---src-pages-keks-pay-thank-you-js": () => import("./../../../src/pages/keks-pay/thank-you.js" /* webpackChunkName: "component---src-pages-keks-pay-thank-you-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-paketi-js": () => import("./../../../src/pages/paketi.js" /* webpackChunkName: "component---src-pages-paketi-js" */),
  "component---src-pages-partneri-going-places-js": () => import("./../../../src/pages/partneri/going-places.js" /* webpackChunkName: "component---src-pages-partneri-going-places-js" */),
  "component---src-pages-partneri-mini-hotel-pms-js": () => import("./../../../src/pages/partneri/mini-hotel-pms.js" /* webpackChunkName: "component---src-pages-partneri-mini-hotel-pms-js" */),
  "component---src-pages-partneri-recepcija-js": () => import("./../../../src/pages/partneri/recepcija.js" /* webpackChunkName: "component---src-pages-partneri-recepcija-js" */),
  "component---src-pages-upute-js": () => import("./../../../src/pages/upute.js" /* webpackChunkName: "component---src-pages-upute-js" */)
}

